import {
  DiscordIcon,
  FacebookIcon,
  Link,
  MediumIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,

} from '@pancakeswap/uikit'
import { cloneElement } from 'react'
import styled from 'styled-components'

const CommunityWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 12px;
  justify-content: center;
`
const CommunityTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  width: 32px;
  height: 32px;
  transition: background-color 0.25s ease-in-out;
  svg path {
    fill: ${({ theme }) => theme.colors.primary};
    transition: fill 0.25s ease-in-out;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary};
    svg path {
      fill: ${({ theme }) => (theme.isDark ? '#000000' : '#ffffff')};
    }
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 48px;
    height: 48px;
  }
`
const communityData = [
  { icon: <FacebookIcon />, href: 'https://www.facebook.com/profile.php?id=61562845527051&mibextid=ZbWKwL', alt: 'FacebookIcon' },
  { icon: <TwitterIcon />, href: 'https://x.com/Dan74668', alt: 'TwitterIcon' },
  { icon: <TelegramIcon />, href: 'https://t.me/dannycoin2024', alt: 'TelegramIcon' },
  // { icon: <DiscordIcon />, href: 'https://discord.gg/ARqdjAyX', alt: 'DiscordIcon' },
  // { icon: <RedditIcon />, href: 'https://www.reddit.com/u/TORNADOSWAP/s/weZ8FswS6U', alt: 'RedditIcon' },
  // { icon: <YoutubeIcon />, href: 'https://www.youtube.com/channel/UC94EOeU2XOIX6sLqywQ3Fcg', alt: 'youtubeIcon' },
  // { icon: <MediumIcon />, href: 'https://medium.com/@tornadoswap9168', alt: 'mediumIcon' },
]

export const CommunityTags: React.FC = () => {
  return (
    <CommunityWrapper>
      {communityData.map((item) => (
        <Link href={item.href} key={item.alt} external>
          <CommunityTag>{cloneElement(item.icon, { width: 18 })}</CommunityTag>
        </Link>
      ))}
      <Link href="https://lin.ee/Bk001rE" external>
        <CommunityTag>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="18"><path d="M311 196.8v81.3c0 2.1-1.6 3.7-3.7 3.7h-13c-1.3 0-2.4-.7-3-1.5l-37.3-50.3v48.2c0 2.1-1.6 3.7-3.7 3.7h-13c-2.1 0-3.7-1.6-3.7-3.7V196.9c0-2.1 1.6-3.7 3.7-3.7h12.9c1.1 0 2.4 .6 3 1.6l37.3 50.3V196.9c0-2.1 1.6-3.7 3.7-3.7h13c2.1-.1 3.8 1.6 3.8 3.5zm-93.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 2.1 1.6 3.7 3.7 3.7h13c2.1 0 3.7-1.6 3.7-3.7V196.8c0-1.9-1.6-3.7-3.7-3.7zm-31.4 68.1H150.3V196.8c0-2.1-1.6-3.7-3.7-3.7h-13c-2.1 0-3.7 1.6-3.7 3.7v81.3c0 1 .3 1.8 1 2.5c.7 .6 1.5 1 2.5 1h52.2c2.1 0 3.7-1.6 3.7-3.7v-13c0-1.9-1.6-3.7-3.5-3.7zm193.7-68.1H327.3c-1.9 0-3.7 1.6-3.7 3.7v81.3c0 1.9 1.6 3.7 3.7 3.7h52.2c2.1 0 3.7-1.6 3.7-3.7V265c0-2.1-1.6-3.7-3.7-3.7H344V247.7h35.5c2.1 0 3.7-1.6 3.7-3.7V230.9c0-2.1-1.6-3.7-3.7-3.7H344V213.5h35.5c2.1 0 3.7-1.6 3.7-3.7v-13c-.1-1.9-1.7-3.7-3.7-3.7zM512 93.4V419.4c-.1 51.2-42.1 92.7-93.4 92.6H92.6C41.4 511.9-.1 469.8 0 418.6V92.6C.1 41.4 42.2-.1 93.4 0H419.4c51.2 .1 92.7 42.1 92.6 93.4zM441.6 233.5c0-83.4-83.7-151.3-186.4-151.3s-186.4 67.9-186.4 151.3c0 74.7 66.3 137.4 155.9 149.3c21.8 4.7 19.3 12.7 14.4 42.1c-.8 4.7-3.8 18.4 16.1 10.1s107.3-63.2 146.5-108.2c27-29.7 39.9-59.8 39.9-93.1z" /></svg>
        </CommunityTag>
      </Link>
    </CommunityWrapper>
  )
}

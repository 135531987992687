import { Button, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import Image from 'next/image'
import ConnectWalletButton from 'components/ConnectWalletButton'
import useTheme from 'hooks/useTheme'
import styled from 'styled-components'
import { useAccount } from 'wagmi'
import { SlideSvgDark, SlideSvgLight } from './SlideSvg'

import badgeDarkImage from '../../../../public/images/home/lunar-bunny/Dex-Badge-Dark.png'
import badgeLightImage from '../../../../public/images/home/lunar-bunny/Dex-Badge-Light.png'
import Link from 'next/link'

const BgWrapper = styled.div`
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
`

const InnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: -3px;
`

const BunnyWrapper = styled.div`
  width: 100%;
  animation: 3.5s ease-in-out 0s infinite;
  > span {
    overflow: visible !important; // make sure the next-image pre-build blur image not be cropped
  }
`



const StyledText = styled(Text)`
  font-size: 32px;
  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 40px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 55px;
  }
  ${({ theme }) => theme.mediaQueries.xxl} {
    font-size: 55px;
  }
`


const Hero = () => {
  const { address: account } = useAccount()
  const { theme, isDark } = useTheme()
  const { isMobile, isXs, isMd } = useMatchBreakpoints()



  return (
    <>
      <style jsx global>
        {`
          .slide-svg-dark {
            display: none;
          }
          .slide-svg-light {
            display: block;
          }
          [data-theme='dark'] .slide-svg-dark {
            display: block;
          }
          [data-theme='dark'] .slide-svg-light {
            display: none;
          }
        `}
      </style>
      <BgWrapper>
        <InnerWrapper>
          <SlideSvgDark className="slide-svg-dark" width="100%" />
          <SlideSvgLight className="slide-svg-light" width="100%" />
        </InnerWrapper>
      </BgWrapper>
      <Flex
        position="relative"
        flexDirection={['column-reverse', null, null, 'row']}
        alignItems={['center', null, null, 'center']}
        justifyContent="center"
        pl={['0px', '0px', '0px', '30px']}
        marginTop="80px"
        id="homepage-hero"
      >
        <Flex flex="1" flexDirection="column">
          <Text textAlign={isMobile || isMd ? 'center' : 'left'} fontSize="15px" mb="16px">
            <StyledText display="inline-block" lineHeight="110%" fontWeight={600} color="text" mr="8px">
              Welcome to DeFi <StyledText color='primary' fontWeight={600}>DANNY Exchange</StyledText>
            </StyledText>
          </Text>
          <Text
            mb="24px"
            color={theme.isDark ? '#f4eeff' : '#280d5f'}
            maxWidth={600}
            fontSize={['20px', '20px', null, '24px']}
            textAlign={isMobile ? 'center' : 'left'}
            fontWeight={600}
          >
            Trade any tokens at the best rates with our DEX aggregator. Support all tokens with highest security system!
          </Text>

          <Flex justifyContent={isMobile || isMd ? 'center' : 'start'}>
            {!account && <ConnectWalletButton style={{ borderRadius: isXs ? 12 : undefined }} scale="md" mr="8px" />}
            <Link href="/swap">
            <Button
              scale="md"
              style={{ borderRadius: isXs ? 12 : undefined }}
              variant={!account ? 'secondary' : 'secondary'}
            >
              Trade Now
            </Button>
            </Link>
          </Flex>
        </Flex>
        <Flex
          height={['100%', null, null, '100%']}
          width={['100%', null, null, '100%']}
          flex={[null, null, null, '1']}
          mb={['24px', null, null, '0']}
          position="relative"
        >
          <BunnyWrapper>
            {isDark ? (
              <Image src={badgeDarkImage} height="600px" width="600px" priority alt='badgeLightImage' />
            ) : (
              <Image src={badgeLightImage} height="600px" width="600px" priority alt='badgeLightImage' />
            )}
          </BunnyWrapper>
        </Flex>
      </Flex>
    </>
  )
}

export default Hero

import styled from 'styled-components'
import PageSection from 'components/PageSection'
import useTheme from 'hooks/useTheme'
import { Box, Flex, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { PageMeta } from 'components/Layout/Page'
import Hero from './components/Hero'
import {
  InnerWedgeWrapper,
  OuterWedgeWrapper,
  WedgeTopLeft,
  WedgeTopRight,
} from './components/WedgeSvgs'
import CommonSection from './components/CommonSection'
import commonImg from '../../../public/images/common-1.png';
import commonImg2 from '../../../public/images/common-2.png';
import commonImg3 from '../../../public/images/common-3.png';
import { RightTopBox } from './CommunitySection/ImagesOnBg'
import CommunitySection from './CommunitySection'





const StyledHeroSection = styled(PageSection)`
  margin-top: 0px;
  
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 0px;
  }
`


const Home: React.FC = () => {
  const { theme, isDark } = useTheme()
  const { isMobile } = useMatchBreakpoints();
  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px', padding: '0px 16px' }

  return (
    <Box marginTop={0} style={{ width: isMobile ? '100vw' : 'calc(100vw - 8px)', overflow: 'hidden', boxSizing: 'border-box' }}>
      <PageMeta />
      <style jsx global>{`
      #home-1 .page-bg {
        background: linear-gradient(139.73deg, #ecf8ff 0%, #ecf8ff 100%);
      }
      [data-theme='dark'] #home-1 .page-bg {
        background: radial-gradient(103.12% 50% at 50% 50%, #21193a 0%, #191326 100%);
      }
      #home-2 .page-bg {
        background: linear-gradient(180deg, #ffffff 22%, #d7caec 100%);
      }
      [data-theme='dark'] #home-2 .page-bg {
        background: linear-gradient(180deg, #09070c 22%, #201335 100%);
      }
      #home-3 .page-bg {
        background: linear-gradient(180deg, #6fb6f1 0%, #eaf2f6 100%);
      }
      [data-theme='dark'] #home-3 .page-bg {
        background: linear-gradient(180deg, #0b4576 0%, #091115 100%);
      }
      #home-4 .inner-wedge svg {
        fill: #d8cbed;
      }
      [data-theme='dark'] #home-4 .inner-wedge svg {
        fill: #201335;
      }

      #bottom-wedge4-2 svg {
        fill: #72b8f2;
      }
      [data-theme='dark'] #bottom-wedge4-2 svg {
        fill: #0b4576;
      }
    `}</style>

      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%', overflow: 'visible', padding: '16px' } }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
        className='page-bg'
        marginTop={0}
      >
        <Hero />
      </StyledHeroSection>

      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-2',
        }}
        index={2}
        hasCurvedDivider={false}
        className='page-bg'
      >
        <Flex justifyContent="center" alignItems="center" flexDirection="column" pt={isMobile ? '24px' : '60px'}>
          <img src="/images/logo.png" alt='logo' height="48px" width="48px" />

          <Text
            textAlign="center"
            fontSize={['22px', null, null, '34px']}
            
            display="inline-block"
            bold
            mt="10px"
            color={theme.colors.text}
          >
            One Stop <Text
              textAlign="center"
              fontSize={['22px', null, null, '34px']}
              
              display="inline-block"
              bold
              mt="25px"
              color="#ff9327"
            >
              All Your DeFi
            </Text>
          </Text>




          <Text
            fontSize={['15px', null, null, '15px']}
            ml="8px"
            mt="15px"
            display="inline-block"
            bold
            
            color={theme.colors.secondary}
          >
            Swap, Liquidity Pools, Stake,

          </Text>
          <Text
            fontSize={['15px', null, null, '15px']}
            ml="8px"
            mt="10px"
            display="inline-block"
            bold
            
            color={theme.colors.secondary}
          >
            NFTs, Marketplace and Payment

          </Text>
        </Flex>
      </PageSection>

      <PageSection
        index={2}
        hasCurvedDivider={false}
        backgroundColor={isDark ? "#08060b" : "#faf9fa"}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper top>
            <WedgeTopLeft />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <CommonSection
          title="Trade any token at the best rate & Low fee"
          description="Best trading offers on binance chains with the low fee rates thanks to our Exchange. DEX aggregator for liquidity pools to route your trade, achieving the best possible output. Which is fully under your control."
          buttonText="Trade Now"
          imageSrc={commonImg}
          learnLink='https://dannygroup.gitbook.io/web3academy/'

        />
      </PageSection>

      <PageSection
        index={2}
        hasCurvedDivider={false}
        backgroundColor={isDark ? "#27262d" : "#ffffff"}
      >

        <OuterWedgeWrapper>
          <InnerWedgeWrapper top>
            <WedgeTopRight />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>

        <CommonSection
          title="Manage Pool By Yourself"
          description="Owner project can manage Pools yourself easily, such as adding pools, moving pools, deleting pools, import pools. With a system that connects all Dex in one place."
          buttonText="Add Pools"
          imageSrc={commonImg2}
          buttonLink='/liquidity'
          isReverse={true}
          learnLink="https://dannygroup.gitbook.io/web3academy/"

        />
      </PageSection>

      <PageSection
        index={2}
        hasCurvedDivider={false}
        background={isDark ? "linear-gradient(166.77deg,#3b4155,#3a3045)" : "linear-gradient(111.68deg,#f2ecf2,#e8f2f6)"}
      >
        <CommonSection
          title="Trade Support 1Million+ Token Pairs"
          description="Support trading of all tokens on Binance smart chain, no matter where the token is listed Other exchanges. You can trade and manage the system here as a one-stop service. Just connect your wallet to the system."
          buttonText="Trade Now"
          imageSrc={commonImg3}
          learnLink='https://dannygroup.gitbook.io/web3academy/'

        />
      </PageSection>

      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        containerProps={{
          id: 'home-3',
        }}
        index={2}
        hasCurvedDivider={false}
        marginTop={isMobile ? "70px" : "50px"}
        marginBottom={isMobile ? "50px" : "0px"}
      >
        <RightTopBox />
        <CommunitySection />
      </PageSection>
    </Box>
  )
}

export default Home